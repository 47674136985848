import React from "react";
import "./Footer.scss";
import logo from "../../assets/images/logo.svg";

export default function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-top">
          <div className="footer-contact-col">
            <h1 className="contact-title">Contact</h1>
            <a
              href="https://maps.app.goo.gl/ykhxiQ2MzFHk4zp88"
              target="_blank"
              rel="noopener noreferrer"
              className="location-row-wrapper"
            >
              <p className="contact-location">
                SCO 76, 2nd Floor, Tribune Rd, 20C, Sector 20, Chandigarh,
                160020 , <br />
                <a style={{ textDecoration: "none" }} href="tel:0172-5039796">
                  {" "}
                  <span
                    className="contact-number"
                    style={{ textDecoration: "none" }}
                  >
                    Tel : 0172-5039796
                  </span>
                </a>
                <svg
                  width="42"
                  height="43"
                  viewBox="0 0 47 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.61891 8.34923L2.56428 4.0903C2.54114 2.28697 3.98045 0.847665 5.77431 0.880268L43.059 1.34898C44.8623 1.37212 46.339 2.84883 46.3524 4.64245L46.8309 41.9368C46.854 43.7402 45.4147 45.1795 43.6208 45.1469L39.3619 45.0922C37.5394 45.0688 36.0525 43.544 36.0675 41.722L36.3247 19.1586L9.11041 46.3729C7.85102 47.6323 5.79829 47.606 4.50617 46.3138L1.39729 43.205C0.105168 41.9128 0.0788343 39.8601 1.33823 38.6007L28.5525 11.3865L5.98911 11.6437C4.15762 11.6681 2.63282 10.1812 2.61891 8.34923Z"
                    fill="#dac6b8"
                  />
                </svg>
              </p>
            </a>
            <a href="tel:+91 98881 28881" className="contact-row-wrapper">
              <p className="contact-number">+91 98881 28881</p>
              <svg
                width="42"
                height="43"
                viewBox="0 0 47 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.61891 8.34923L2.56428 4.0903C2.54114 2.28697 3.98045 0.847665 5.77431 0.880268L43.059 1.34898C44.8623 1.37212 46.339 2.84883 46.3524 4.64245L46.8309 41.9368C46.854 43.7402 45.4147 45.1795 43.6208 45.1469L39.3619 45.0922C37.5394 45.0688 36.0525 43.544 36.0675 41.722L36.3247 19.1586L9.11041 46.3729C7.85102 47.6323 5.79829 47.606 4.50617 46.3138L1.39729 43.205C0.105168 41.9128 0.0788343 39.8601 1.33823 38.6007L28.5525 11.3865L5.98911 11.6437C4.15762 11.6681 2.63282 10.1812 2.61891 8.34923Z"
                  fill="#dac6b8"
                />
              </svg>
            </a>
          </div>
          <div className="footer-timings-col">
            <h1 className="timings-title">Office Hours</h1>
            <div className="timing-wrapper">
              <p className="timing-days">Monday - Saturday</p>
              <p className="timings-times">10:30 AM - 6:30 PM</p>
            </div>
            <p className="off-day">Closed on Sunday</p>
          </div>
          <iframe
            className="footer-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.087833521176!2d76.77539767557973!3d30.7159310745924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0040000001%3A0x1e5a99dc360e6a13!2sNew%20Tech%20Hearing%20Aid%20Centre%20-%20Best%20Hearing%20Aid%20Centre%20In%20Tricity!5e0!3m2!1sen!2sin!4v1727493850068!5m2!1sen!2sin"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="New Tech Hearing Aid Centre Location Map"
          ></iframe>

          {/* <div className="up_page-btn"></div> */}
        </div>
        <div className="footer-bottom">
          <p className="brand-subtitle">Your Hearing, Our Priority</p>
          <div className="footer-brand-container">
            <img className="footer-logo" src={logo} alt="" />
            <h1 className="footer-title">New Tech Hearing Aid Centre</h1>
          </div>
        </div>
      </div>
    </footer>
  );
}
