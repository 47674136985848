import React from "react";
import "./Navbar.scss";
import logo from "../../assets/images/logo.svg";

export default function Navbar() {
  return (
    <nav className="navbar fixed-top">
      <div className="container-fluid">
        <div className="brand-container">
          <img className="logo img-fluid" src={logo} alt="" />
          <div className="brand-wrapper ms-2">
            <h1 className="brand-title">
              New Tech <br /> Hearing Aid Centre
            </h1>
          </div>
        </div>
        <a href="tel:+91 98881 28881" className="cta-container ">
          <svg
            className="contact-svg"
            width="67"
            height="66"
            viewBox="0 0 67 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_579_228)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.27561 2.10808C8.99738 1.3874 9.86407 0.828337 10.8183 0.467943C11.7724 0.107549 12.7923 -0.0459468 13.8103 0.0176286C14.8283 0.081204 15.8211 0.360398 16.723 0.83671C17.625 1.31302 18.4154 1.97557 19.0419 2.78046L26.4462 12.2927C27.8034 14.0376 28.2819 16.3105 27.7456 18.4555L25.4892 27.4892C25.3734 27.9571 25.3801 28.447 25.5087 28.9116C25.6373 29.3762 25.8835 29.7998 26.2235 30.1416L36.3586 40.2767C36.7008 40.6174 37.1251 40.864 37.5905 40.9926C38.0559 41.1212 38.5466 41.1276 39.0151 41.011L48.0447 38.7546C49.1034 38.4915 50.2079 38.4717 51.2753 38.6967C52.3427 38.9218 53.3452 39.3858 54.2075 40.054L63.7197 47.4542C67.1394 50.1148 67.4529 55.168 64.3921 58.2246L60.1269 62.4898C57.0744 65.5423 52.5121 66.883 48.2592 65.3856C37.372 61.56 27.488 55.3278 19.343 47.1531C11.1688 39.0093 4.93664 29.1267 1.11049 18.241C-0.382761 13.9922 0.957864 9.42583 4.01036 6.37333L8.27561 2.10808Z"
                fill=" #dac6b8"
              />
            </g>
            <defs>
              <clipPath id="clip0_579_228">
                <rect
                  width="66"
                  height="66"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <span className="d-none d-md-block">+91 98881 28881</span>
        </a>
      </div>
    </nav>
  );
}
