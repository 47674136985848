import React from "react";
import "./Home.scss";
import { Helmet } from "react-helmet";
import Accessories from "../components/Accessories/Accessories";
import CircularText from "../components/CircularText/CircularText";
import hearingAidRepairing from "../assets/images/hearing_aid_repairing.jpg";
import hearingLossTreatment from "../assets/images/consulting.jpg";
import hearingAidRepairing1 from "../assets/images/hearing_aid_repair.jpg";
import customEarmold from "../assets/images/custom_earmolds.jpg";
import caringHearingAid from "../assets/images/caring_hearing_aids.jpg";
import hearingAid from "../assets/images/hearing-aid.jpg";
import homeVisit from "../assets/images/home_visit.jpg";
import accessories from "../assets/images/accessories.jpg";

export default function Home() {
  return (
    <div className="page-content">
      {/* SEO Metadata and Optimization */}
      <Helmet>
        <title>
          Expert Care for Your Hearing Aids | New Tech Hearing Aid Centre
        </title>
        <meta
          name="description"
          content="New Tech Hearing Aid Centre offers personalized hearing aid fittings, expert counseling, repairs, and home visits. We specialize in digital, rechargeable, and noise-canceling hearing aids. Based in Chandigarh, we strive to enhance your hearing with the latest technology. Get expert care with professional hearing aid repair, maintenance, and accessories. Call us today for a free consultation!"
        />

        {/* JSON-LD Structured Data for Schema.org */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "New Tech Hearing Aid Centre",
            "url": "https://www.newtechhearingaidcentre.com",
            "logo": "https://www.newtechhearingaidcentre.com/assets/android-chrome-192x192.png",
            "image": "https://www.newtechhearingaidcentre.com/assets/images/hearing_aid_repairing.jpg",
            "description": "New Tech Hearing Aid Centre offers personalized consultations, hearing aid fitting, repairs, custom earmolds, and home visits. We specialize in digital, rechargeable, and noise-canceling hearing aids tailored to your unique hearing needs.",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91 98881 28881",
              "contactType": "Customer Service and Repair",
              "areaServed": "IN",
              "availableLanguage": "English"
            },
            "sameAs": [
              "https://www.facebook.com/NewTechHearingAidCentre",
              "https://twitter.com/NewTechHearingAidCentre",
              "https://www.instagram.com/NewTechHearingAidCentre"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "SCO 76, 2nd Floor, Tribune Rd, 20C, Sector 20",
              "addressLocality": "Chandigarh",
              "addressRegion": "Chandigarh",
              "postalCode": "160020",
              "addressCountry": "IN"
            }
          }
          `}
        </script>

        {/* Breadcrumb Schema (Only for Home) */}
        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.newtechhearingaidcentre.com/"
        }
      ]
    }
    `}
        </script>

        <meta
          name="keywords"
          content="hearing aids, best hearing aid service , expert hearing aid fitting chandigarh, professional hearing aid fitting chandigarh , tricity, affordable, repairs , expert hearing aid fitting in tricity, hearing aid center in Chandigarh, hearing aid repairs, hearing aid fitting, hearing aid consultation, hearing aids for old ages, home visit for seniors ,custom earmolds, chandigarh hearing aid centre, hearing aid centre in chandigarh, digital hearing aids, behind-the-ear hearing aids, receiver-in-canal hearing aids, in-the-ear hearing aids, in-the-canal hearing aids, rechargeable hearing aids, hearing aid batteries, hearing aid accessories, hearing aid home visit fitting, hearing aid maintenance, expert hearing aid services, hearing aid technician, hearing aid adjustments, hearing aid brands, hearing loss treatment, hearing aid counseling, hearing aid tuning, hearing aid repairs near me, hearing aid clinic, hearing aid service Centre, audiology services, hearing loss solutions, affordable hearing aids, advanced hearing aid technology, noise reduction hearing aids, tinnitus relief hearing aids, hearing aid troubleshooting, hearing aid fitting near me, online hearing aid fitting, hearing aids for seniors, hearing aid solutions, hearing aid store, hearing aid experts, how to choose a hearing aid for seniors , hearing aid repair services in Chandigarh, affordable hearing aids for tinnitus relief"
        />

        <meta name="robots" content="index, follow" />

        {/* Open Graph tags for social media */}
        <meta
          property="og:title"
          content="Expert Care for Your Hearing Aids | New Tech Hearing Aid Centre"
        />
        <meta
          property="og:description"
          content="We specialize in hearing aid counseling, repairs, custom earmolds, and home visits. Providing personalized care for every client."
        />
        <meta
          property="og:image"
          content="https://www.newtechhearingaidcentre.com/assets/logo.png"
        />
        <meta
          property="og:url"
          content="https://newtechhearingaidcentre.com/"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Expert Care for Your Hearing Aids | New Tech Hearing Aid Centre"
        />
        <meta
          name="twitter:description"
          content="Offering expert care, personalized fittings, repairs, and home visits. Enhance your hearing with our expert team."
        />
        <meta
          name="twitter:image"
          content="https://www.newtechhearingaidcentre.com/assets/logo.png"
        />
      </Helmet>
      {/* Hero Section */}
      <div className="hero-container">
        <h1 className="hero__title">
          Expert Care for Your{" "}
          <span className="hero__title-italics">
            Hearing <br /> Aids
          </span>
        </h1>
        <img
          className="hero-img"
          src={hearingAidRepairing}
          alt="Technician repairing a hearing aid"
          loading="lazy"
        />
        <p className="hero__content">
          At New Tech Hearing Aid Centre, we specialize in delivering
          comprehensive hearing care services. From personalized hearing aid
          fittings and expert counseling to reliable repairs and home visits, we
          ensure that every solution is tailored to your unique hearing needs.
          Backed by the latest technology and a commitment to exceptional care,
          we strive to enhance your hearing and enrich your quality of life.
        </p>
      </div>
      <div className="service-section-1">
        <img
          src={hearingLossTreatment}
          alt="Expert hearing aid technician treating or guidance a patient"
          className="service_1-img"
          loading="lazy"
        />
        <div className="service_1-col ">
          <h1 className="service_1-title">
            Hearing Aid{" "}
            <span className="service_1-title-italics">
              Counselling and Dispensing
            </span>
          </h1>
          <p className="service_1-content">
            Receive expert guidance on choosing the right hearing aid for your
            lifestyle and hearing needs. Our hearing aid technician ensures that
            each device is carefully selected, custom-fitted, and finely tuned
            for optimal performance, providing you with the best hearing
            solution.
          </p>
        </div>
      </div>
      <div className="service-section-2">
        <div className="service_2-col">
          <h1 className="service_2-title">
            Hearing Aid{" "}
            <span className="service_2-title-italics">
              Repairs (All Brands)
            </span>
          </h1>
          <img
            src={hearingAidRepairing1}
            alt="Technician repairing a hearing aid"
            className="service_2-img"
            loading="lazy"
          />
        </div>
        <p className="service_2-content">
          Our expert technicians can repair and service any brand or type of
          hearing aid, ensuring it functions like new. Fast, reliable repairs to
          keep you connected to the world of sound.
        </p>
      </div>
      <div className="service-section-3">
        <img
          src={customEarmold}
          alt="Custom earmolds for hearing aids include sft silicon and UV molds"
          className="service_3-img"
          loading="lazy"
        />
        <div className="service_3-col">
          <h1 className="service_3-title">
            Earmold Facility{" "}
            <span className="service_3-title-italics">
              (Including Soft & UV Molds)
            </span>
          </h1>
          <p className="service_3-content">
            We offer custom earmolds designed for maximum comfort and
            performance. Whether you need soft or UV-cured molds, we provide
            solutions tailored to your ears for a perfect fit.
          </p>
        </div>
      </div>
      <div className="service-section-4">
        <div className="service_4-col">
          <h1 className="service_4-title">
            Hearing Aid{" "}
            <span className="service_4-title-italics">
              Service and Maintenance
            </span>
          </h1>
          <p className="service_4-content ">
            Our experienced hearing aid technician provides expert care and
            maintenance for all types of hearing aids. From routine checkups to
            detailed repairs, you can rely on personalized, hands-on service to
            keep your hearing aids functioning at their best. We ensure that
            every device is carefully serviced to match your specific
            requirements, extending its lifespan and performance.
          </p>
        </div>
        <img
          src={caringHearingAid}
          alt="Caring of hearing aid done by the technician"
          className="service_4-img"
          loading="lazy"
        />
      </div>
      <div className="service-section-5">
        <div className="service_5-col">
          <h1 className="service_5-title">
            <span className="service_5-title-italics">100% Digital</span> <br />
            Hearing Aid{" "}
          </h1>
          <img
            src={hearingAid}
            alt="Digital hearing aid"
            className="service_5-img"
            loading="lazy"
          />
        </div>
        <p className="service_5-content ">
          Our state-of-the-art digital hearing aids use advanced technology to
          provide clearer, more natural sound than traditional analog devices.
          With features like noise reduction, automatic adjustments for
          different environments, and the ability to connect to smartphones or
          other devices, digital hearing aids offer enhanced hearing precision
          and comfort. Whether you’re in a quiet room or a noisy environment,
          digital aids adapt to your needs, making them an excellent choice for
          modern hearing solutions.
        </p>
      </div>
      <div className="service-section-6">
        <img
          src={homeVisit}
          alt="Home visit hearing aid consulting"
          className="service_6-img"
          loading="lazy"
        />
        <div className="service_6-col">
          <h1 className="service_6-title">
            <span className="service_6-title-italics">Home Visit</span> <br />
            for Hearing Aid Fitting{" "}
          </h1>
          <p className="service_6-content">
            We offer the convenience of home visits to ensure your hearing aids
            are perfectly fitted and adjusted in a setting you're most
            comfortable in. This service allows for personalized care and
            attention, making the fitting process more accessible for
            individuals who may prefer the comfort of their own environment. We
            are dedicated to providing expert support with the same level of
            care and professionalism as our clinic visits, tailored to your
            specific hearing needs.
          </p>
        </div>
      </div>
      <div className="service-section-7">
        <img
          src={accessories}
          alt="Hearing aid accessories"
          className="service_7-img"
          loading="lazy"
        />
        <div className="service_7-col">
          <h1 className="service_7-title">
            Hearing Aids{" "}
            <span className="service_7-title-italics">
              Accessories and Batteries
            </span>{" "}
            <br />
          </h1>
          <p className="service_7-content ">
            Explore our comprehensive selection of hearing accessories,
            including cleaning kits, ear hooks, tubes, domes, and battery
            chargers, all designed to enhance your hearing aid’s performance and
            durability. Discover the perfect solutions to keep your devices
            running smoothly and comfortably.
          </p>
        </div>
      </div>
      <Accessories />
      <div className="service-section-8">
        <div className="service_8-spinner-text">
          {" "}
          <CircularText />{" "}
        </div>
        <p className="service_8-content ">
          Benefit from our exclusive discounts on hearing aids and related
          services. Contact us to find out more about our current offers and
          promotions.
        </p>
      </div>
    </div>
  );
}
