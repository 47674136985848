import React from "react";
import "./Accessories.scss";

export default function Accessories() {
  const accessoriesData = [
    {
      img: require("../../assets/accessories/image1.jpg"),
      title: "Hearing Aid Batteries",
      desc: "Reliable, long-lasting batteries available in various sizes, designed to keep your hearing aids powered and functioning optimally.",
    },
    {
      img: require("../../assets/accessories/image2.jpg"),
      title: "Earwax Guards",
      desc: "Designed to help prevent earwax from clogging your hearing aids, ensuring optimal sound quality and performance for a better listening experience.",
    },
    {
      img: require("../../assets/accessories/image3.jpg"),
      title: "TV Adapters",
      desc: "Enjoy direct sound streaming from your phones, TVs, or music players into your hearing aids, allowing you to enhance your listening experience and enjoy your favorite shows and music with clarity.",
    },
    {
      img: require("../../assets/accessories/image4.jpg"),
      title: "Carrying Cases",
      desc: "Durable protective cases designed for safe storage and travel, ensuring your devices are secure and easily accessible wherever you go.",
    },
    {
      img: require("../../assets/accessories/image5.jpg"),
      title: "Cleaning Kits",
      desc: "Essential tools for routine cleaning, designed to maintain your hearing aids and enhance their longevity for a better listening experience.",
    },
    {
      img: require("../../assets/accessories/image6.jpg"),
      title: "Drying Boxes & Dehumidifiers",
      desc: "Safeguard your devices from moisture damage with our drying boxes and dehumidifiers, designed to keep your hearing aids dry and in top condition.",
    },
    {
      img: require("../../assets/accessories/image7.jpg"),
      title: "Remote Controls",
      desc: "Conveniently adjust your hearing aid settings, including volume and modes, with easy-to-use remote controls that let you make changes without removing your devices.",
    },
    {
      img: require("../../assets/accessories/image8.jpg"),
      title: "Hearing Aid Charging Case",
      desc: "Keep your hearing aids powered on the go with a sleek, protective charging case that combines portability and convenience for your active lifestyle.",
    },
    {
      img: require("../../assets/accessories/image9.jpg"),
      title: "Ear Hooks",
      desc: "Offers additional security for behind-the-ear hearing aids, ensuring they stay comfortably in place during all your daily activities.",
    },
    {
      img: require("../../assets/accessories/image10.jpg"),
      title: "Ear Dome",
      desc: "Soft domes that fit into the ear canal, providing both comfort and improved acoustics for better sound quality.",
    },
    {
      img: require("../../assets/accessories/image11.jpg"),
      title: "Ear Tubes",
      desc: "A thin, flexible tube connecting the hearing aid to the earpiece, designed for optimal sound transmission.",
    },
    {
      img: require("../../assets/accessories/image12.jpg"),
      title: "Earmolds",
      desc: "Earmolds are specially designed pieces that fit comfortably inside of your ears to provide additional comfort and noise reduction, as well as protection from wind, moisture and dust.",
    },
  ];

  return (
    <div className="accessory-wrapper">
      {accessoriesData.map((accessory, index) => (
        <div className="accessory-container" key={index}>
          <img
            className="accessory-img"
            src={accessory.img}
            alt={accessory.title}
            loading="lazy"
          />
          <div className="accessory-title">{accessory.title}</div>
          <div className="accessory-desc">{accessory.desc}</div>
        </div>
      ))}
    </div>
  );
}
