import React from "react";

import "./App.css";
import Home from "./Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Navbar />
      <div className="page-wrapper">
        <Home />
      </div>
      <Footer />
    </>
  );
}

export default App;
